import React from 'react'
import styled from 'styled-components/macro'
import { Button, Subheading as SubheadingRef, Title as TitleRef } from '../Hero'
import { Col as ColRef } from '../../../components/base/layouts/Col'
import { useNavigate } from 'react-router-dom'

// Styled container for layout
const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  background: url() no-repeat center center;
  background-size: cover;
  background-color: #f8f9fa;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('/bg-gimq.jpg');
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
`

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 90%;
  min-height: 55vh;
  margin: 1rem auto;
  opacity: 0.9;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

// Styled component for main title
const H2 = styled.h2`
  color: #333;
  margin-bottom: 15px;
`

// Styled component for subtitle
const SubH = styled.h3`
  color: #555;
  margin-bottom: 20px;
`

// Styled component for customer pain points
const PainPoints = styled.div`
  color: #007bff;
  font-weight: bold;
  margin-bottom: 2.5rem;
  text-align: left;
  font-size: 1.65rem;
  line-height: 2;

  @media (max-width: 768px) {
    text-align: left;
    font-size: 1.6rem;
  }
`

const Col = styled(ColRef)`
  padding: 0 2rem;
  width: 100%;
  height: 100%;

  > * {
    color: #1b75bc;
  }
`

const Title = styled(TitleRef)`
  font-size: 6rem;
  margin-top: 0;

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`

const Subheading = styled(SubheadingRef)`
  font-size: 2.1rem;

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
`

// // Styled button
// const Button = styled.button`
//   background-color: #007bff;
//   color: white;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 5px;
//   font-size: 1.4rem;
//   cursor: pointer;
//   transition: background-color 0.3s;

//   &:hover {
//     background-color: #0056b3;
//   }
// `

export const CTASecondary = () => {
  const navigate = useNavigate()

  return (
    <Layout>
      <Card>
        <Col>
          <Title>Une consultation gratuite pour un nouveau départ!</Title>
          <Subheading>Découvrez comment améliorer votre gestion immobilière avec GIMQ.</Subheading>
        </Col>
        <Col>
          <PainPoints>
            Vous venez d'acheter un nouvel immeuble et cherchez des conseils?
            <br />
            Vous faites face à des défis avec vos locataires actuels?
            <br />
            {/* Vous envisagez d'acheter votre première maison? */}
            Vous voulez profiter de la vie et confier votre parc immobilier à un partenaire de confiance?
            <br />
            Vous avez hérité d'une propriété et vous ne savez pas par où commencer?
          </PainPoints>
          <Button to='/contact' style={{ margin: '0 auto' }}>
            Réservez votre consultation gratuite dès maintenant
          </Button>
        </Col>
      </Card>
    </Layout>
  )
}
