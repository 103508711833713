import styled from 'styled-components'
import { useEffect } from 'react'
// import { sendPageView } from '../../../utils/analytics'

export const StyledPage = styled.main`
  max-width: ${({ maxWidth }) => maxWidth || '90%'};
  margin: 0 auto;
  padding-top: 12.2rem;
  padding-bottom: 3.5rem;
`

export const Page = ({ className, children }) => {
  // useEffect(() => {
  //   sendPageView()
  // }, [])

  return <StyledPage className={className}>{children}</StyledPage>
}
