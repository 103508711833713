import React from 'react'
import styled, { keyframes } from 'styled-components'

const Layout = styled.div`
  background-color: #f4f4f4;
  padding: 4rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const TestimonialCard = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  max-width: 105rem;
  text-align: center;
  animation: ${fadeIn} 1s ease-out;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0.4rem;
  }
`

const TestimonialPhoto = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
`

const TestimonialText = styled.p`
  font-style: italic;
  color: #555;
  margin-bottom: 1.5rem;
  line-height: 1.85;
  text-align: justify;
  width: 97%;
  margin: 2rem auto;
`

const ClientName = styled.p`
  font-weight: bold;
  color: #1b75bc;
  font-size: 1.8rem;
  margin-right: auto;
`

const Title = styled.h2`
  font-size: 3.5rem;
  color: #1b75bc;
  margin-bottom: 3rem;
  text-align: center;
`

// Données fictives pour l'exemple
const testimonials = [
  {
    text: (
      <>
        Nous avions moi et mon associé certaines difficultés avec un immeuble de quatre logements. D'ailleurs, l'immeuble était à vendre. Un des locataires avait cessé de payer depuis quelques mois et
        un autre avait plusieurs chiens malgré l'interdiction. Les lieux communs avaient été pris en charge par un locataire et étaient encombrés par ses effets. Une voiture avait été laissée à
        l'abandon dans l'allée. Gestion immobilière Mauricie Québec ont réglés les problèmes un à un. Ils ont obtenu la collaboration des locataires concernés. Certains ont quitté. Notre gestionnaire
        Marco a planifié et coordonné certaines rénovations et il a procédé à la location des 2 appartements rafraîchis. Par la suite, nous avons pu vendre notre quadruplex.{' '}
        <b>Nous sommes d'avis que cet immeuble aurait été très difficile à vendre, n'eût été de l'aide de Marco et de son équipe de Gestion immobilière Mauricie Québec.</b> Nous sommes d'ailleurs
        encore clients chez eux et très satisfaits. Cette collaboration nous encourage à poursuivre nos investissements en immobilier.
      </>
    ),
    client: 'Daniel M.',
    photo: '/img/daniel.jpg',
  },
  // { text: 'Une équipe vraiment professionnelle qui a livré plus que prévu.', client: 'John Smith' },
  // Ajoutez d'autres témoignages si nécessaire
]

export const ClientTestimonials = () => {
  return (
    <Layout>
      <Title>Témoignages de Nos Clients</Title>
      {testimonials.map((testimonial, index) => (
        <TestimonialCard key={index}>
          <TestimonialPhoto src={testimonial.photo} />
          <TestimonialText>"{testimonial.text}"</TestimonialText>
          <ClientName>- {testimonial.client}</ClientName>
        </TestimonialCard>
      ))}
    </Layout>
  )
}
