import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import GlobalStyle from './theme/global-styles'
import { Header } from './theme/header/index'
import { ScrollToTop } from '../utils/routing/ScrollToTop'

import { PrivateRoute } from '../utils/api/routing/PrivateRoute'

import { setAuthToken } from '../utils/api'
import useAuthStore from '../features/auth/authStore'
import { FacebookAuth } from '../features/FacebookAuth/FacebookAuth'
import { Homepage } from '../pages/Home'
import styled from 'styled-components'

const Home = lazy(() => import('../pages/Home'))
const ServicesPage = lazy(() => import('../pages/Services'))
const Contact = lazy(() => import('../pages/Contact'))
const RealisationsPage = lazy(() => import('../pages/Realisations'))
const ClientLogin = lazy(() => import('../pages/ClientLogin'))
const Footer = lazy(() => import('./theme/footer'))

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const App = () => {
  const [loading, setLoading] = useState(false)

  const [menuOpened, setMenuOpened] = useState(false)

  const { checkAuthentication, isAuthenticated, loaded, setNoAuth, setUserMode } = useAuthStore()

  useEffect(() => {
    const storedToken = localStorage.getItem('token')

    if (storedToken) {
      setAuthToken(storedToken)
      checkAuthentication()
    } else {
      // dispatch({ type: 'LOGOUT' })
    }
  }, [])

  useEffect(() => {
    var chatbox = document.getElementById('fb-customer-chat')
    chatbox.setAttribute('page_id', '100156852928498')
    chatbox.setAttribute('attribution', 'biz_inbox')
  }, [])

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />

      {/* <FacebookAuth /> */}

      {/* <!-- Messenger Chat Plugin Code --> */}
      <div id='fb-root'></div>

      {/* <!-- Your Chat Plugin code --> */}
      <div
        id='fb-customer-chat'
        class='fb-customerchat'
        logged_in_greeting='
      Bonjour, comment pouvons-nous vous aider?
      '
        logged_out_greeting='
      Bonjour, comment pouvons-nous vous aider?'
        greeting_dialog_delay='5'></div>

      {!loading && (
        <AppContainer>
          <Header setMenuOpened={setMenuOpened} />
          <Suspense fallback={''}>
            <Routes>
              <Route path='/' element={<Homepage menuOpened={menuOpened} setMenuOpened={setMenuOpened} />} />
              <Route path='/nos-services' element={<ServicesPage />} />
              <Route path='/nos-realisations' element={<RealisationsPage />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/app/login' element={<ClientLogin />} />
              <Route path='/app/dashboard' element={<PrivateRoute requiredAccountType='ADMIN'>{/* <HomeSorApp /> */}</PrivateRoute>} />
            </Routes>
          </Suspense>
        </AppContainer>
      )}

      <Footer />

      <div id='fb-root'></div>

      <div id='fb-customer-chat' class='fb-customerchat'></div>
    </>
  )
}

export default App
