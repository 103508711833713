import { css, createGlobalStyle } from 'styled-components'

const fontFaces = css`
  @font-face {
    font-family: 'Spartan MB';
    src: url('SpartanMB-Light.otf') format('otf');
  }
  @font-face {
    font-family: 'Rhodes';
    src: url('../media/fonts/Rhodes.ttf') format('truetype');
  }
`

const GlobalStyle = createGlobalStyle`

/* primary font */
${fontFaces}

@import url('https://fonts.googleapis.com/css2?family=Marmelad&display=swap');

*{
  box-sizing:border-box;
  -webkit-box-sizing: border-box;
}

#root{
  position:relative;
}

  html,
  body{
    scroll-behavior: smooth;
    background-color: ${({ theme }) => theme.colors.body};
    height: 100%;
    width: 100%;
    font-size: 62.5%;
    /* line-height: 1.5; */
    margin: 0;
    padding: 0;
    font-family: inherit;
    -webkit-font-smoothing: antialiased;
    

    /* transition: 0.3s ease-in-out; */

    /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  }


  h1,h2,h3,h4,h5,h6{
    font-family: 'Marmelad', Helvetica, Arial, sans-serif;
    color: ${({ theme }) => theme.colors.text};
    letter-spacing:-0.95px;
    font-weight:100;
  }
  

  body {
    font-family:'Instrument Sans', 'Inter', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 1.6em;
  }
/*   
  body.font-loaded {
    font-family: 'Abu Dhabi', fallbackFont, sans-serif;
} */


/* // style resets */

  input{
    font-family:inherit;
  }

  button{
    border:0;
    background:transparent;
  }

  a{
    text-decoration:none;
    color: unset;
    margin:0;
  }


  // remove blue border focus
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
      outline: none;
  }

 
`

export default GlobalStyle
