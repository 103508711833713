import React from 'react'
import styled from 'styled-components'

const Layout = styled.div`
  width: 100%;
  padding: 3rem 5rem;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

export const H2 = styled.h2`
  color: #1b75bc;
  font-size: 5rem;
  text-align: left;
  padding: 20px;

  @media screen and (max-width: 768px) {
    font-size: 4.2rem;
    text-align: center;
  }
`

// Container for the entire service section
const Services = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  width: 100%;
  gap: 20px;
  padding: 20px;
  margin: 4rem auto;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

// Styled component for each service card
const ServiceCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`

// Styled component for service titles
const ServiceTitle = styled.h2`
  color: #333;
  font-size: 2rem;
  margin-bottom: 10px;
  color: #1b75bc;
  font-weight: 600;
`

// Styled component for service descriptions
const ServiceDescription = styled.p`
  color: #666;
  font-size: 1.45rem;
`

export const ServicesOverview = () => {
  const services = [
    {
      title: 'Gestion locative',
      description:
        "La gestion locative correspond à tous les aspects qui tournent autour de la location de vos unités de logement. De l'intégration et l'accès à notre logiciel en passant par le service 24/7 aux locataires et les renouvellement et reconductions.",
    },
    {
      title: 'Entretien',
      description:
        "Ce service inclut un premier constat des lieux, le suivi de l'entretient régulier et des signalements des locataires concernant le bâtiment et la gestion de travaux selon vos exigences",
    },
    {
      title: 'Comptabilité',
      description: 'Collectes des loyers, tenue de livres, paiement de factures. Dépôt des loyers dans votre compte en un seul versement, suivi des revenus/dépenses et rapport mensuel et annuel.',
    },
    {
      title: 'Optimisation',
      description:
        "Partage d'idées d'optimisation et gestion de projet à valeurs ajoutées. Planification, organisation et mise en oeuvre de stratégies permettant d'augmenter la valeur de votre bien.",
    },
  ]

  return (
    <Layout>
      <H2>Nos Services</H2>
      <Services>
        {services.map((service, index) => (
          <ServiceCard key={index}>
            <ServiceTitle>{service.title}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
          </ServiceCard>
        ))}
      </Services>
    </Layout>
  )
}
