import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// import logoMark from '../../media/img/logoMark.svg'
import { ReactComponent as LogoMarkRef } from '../../media/img/logoMark.svg' // Adjust path as necessary
// import logoMark from '../../media/img/logoGimq.png'
import { LogoShort } from './Style'
import { NavLink as NavLinkRef, useLocation, useNavigate } from 'react-router-dom'

const HeaderContainer = styled.header`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;
  width: 100%;
  z-index: 100;

  @media (max-width: 768px) {
    /* flex-direction: column; */
    align-items: center;
  }
`

const LogoMark = styled(LogoMarkRef)`
  width: 7.5rem;
  height: 7.5rem;
  cursor: pointer;
  transform: translateX(-1.4rem);

  @media (max-width: 768px) {
    width: 6.4rem;
  }
`

// const Logo = styled.div

//   /* font-size: 15rem; */
//   font-weight: bold;
//   cursor: pointer;

//   > svg {
//     width: 6.4rem !important;
//     transform: translateX(-1.5rem);
//   }

//   @media (max-width: 768px) {
//     width: 15rem;

//     > svg {
//       width: 6.4rem !important;
//       transform: translateX(-1.5rem);
//     }
//   }
// `

const Menu = styled.nav`
  display: flex;
  width: 100%;
  max-width: 70rem;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #f8f8f8; // Changed for better visibility
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: ${(props) => (props.open ? 'translateX(0)' : 'translateX(-100%)')};
    z-index: 10;
  }
`

const List = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: white;
  font-weight: 100;
  width: 100%;
  justify-content: space-between;
  color: ${(props) => (props.isBlueLink ? '#1b75bc' : 'white')};
  color: white;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const NavLink = styled(NavLinkRef)`
  position: relative;
  text-decoration: none;
  color: ${(props) => (props.isBlue ? '#1b75bc' : 'white')};
  font-size: 1.6rem;
  font-weight: 100;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &.active::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.6px;
    bottom: -5px;
    left: 0;
    background-color: ${(props) => (props.isBlue ? '#1b75bc' : 'white')};
    transition: width 0.3s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0.6px;
    bottom: -5px;
    left: 0;
    background-color: ${(props) => (props.isBlue ? '#1b75bc' : 'white')};
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }

  @media (max-width: 768px) {
    color: #1b75bc; // All links blue in mobile
    margin-bottom: 20px;
    font-size: 2.2rem;
  }
`

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  flex-direction: column;
  span {
    height: 3px;
    width: 30px;
    /* background: white; */
    background: #1b75bc;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`

const CloseIcon = styled.div`
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    &:before,
    &:after {
      content: '';
      position: absolute;
      right: -12px;
      height: 2px;
      width: 25px;
      background-color: #1b75bc; // Color to match the theme
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
`

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY)
  const [visible, setVisible] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()

  const isBlueLink = (path) => {
    return ['/nos-services', '/nos-realisations', '/contact', '/app/login'].includes(path)
  }

  const handleScroll = () => {
    const currentScrollPos = window.scrollY
    const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10

    setPrevScrollPos(currentScrollPos)
    setVisible(visible)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos, visible, handleScroll])

  return (
    <HeaderContainer style={{ top: visible ? '0' : '-100%', transition: 'top 0.6s' }}>
      {/* <Logo onClick={() => navigate('/')}> */}
      {/* <img src={logoMark} alt='Logo' style={{ width: '9rem' }} /> */}
      <LogoMark onClick={() => navigate('/')} />
      {/* </Logo> */}

      <Hamburger onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </Hamburger>

      <Menu open={menuOpen}>
        <CloseIcon onClick={() => setMenuOpen(false)} />
        <List isBlueLink={isBlueLink}>
          <NavLink to='/' activeClassName='active' isBlue={isBlueLink(location.pathname)} onClick={() => setMenuOpen(false)}>
            Accueil
          </NavLink>
          <NavLink to='/nos-services' activeClassName='active' isBlue={isBlueLink(location.pathname)} onClick={() => setMenuOpen(false)}>
            Services
          </NavLink>
          <NavLink to='/nos-realisations' activeClassName='active' isBlue={isBlueLink(location.pathname)} onClick={() => setMenuOpen(false)}>
            Réalisations
          </NavLink>
          <NavLink to='/contact' activeClassName='active' isBlue={isBlueLink(location.pathname)} onClick={() => setMenuOpen(false)}>
            Contact
          </NavLink>
          <NavLink to='/app/login' activeClassName='active' isBlue={isBlueLink(location.pathname)} onClick={() => setMenuOpen(false)}>
            Login
          </NavLink>
        </List>
      </Menu>
    </HeaderContainer>
  )
}
