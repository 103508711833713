import styled from 'styled-components'
import { Row } from '../../../components/base/layouts/Row'

import { ReactComponent as SimplificationIcon } from '../../../app/media/img/simplification.svg'
import { ReactComponent as RiskIcon } from '../../../app/media/img/risk.svg'
import { ReactComponent as ApprocheHumaineIcon } from '../../../app/media/img/approcheHumaine.svg'
import { ReactComponent as FinancialSecurityIcon } from '../../../app/media/img/financialSecurity.svg'

const Layout = styled.div`
  background: url('/img/bg-texture.png') no-repeat center center;
  background-size: cover;
  width: 100%;
  min-height: 80vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media screen and (max-width: 768px) {
    padding: 0;
    min-height: 90vh;
  }
`

const List = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 5rem;
  padding: 0 5rem;

  @media screen and (max-width: 768px) {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 2rem;
  }
`

const ListItem = styled.li`
  padding: 0 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;

  svg {
    width: 5rem;
    height: 5rem;
    margin-right: 0rem;
    fill: white;

    @media screen and (max-width: 768px) {
      width: 3.4rem;
      height: 3.4rem;
      margin-right: 0rem;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0 1rem;
  }
`

const ListIcon = styled.svg`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
`

const StyledSvg = styled.svg`
  fill: blue;
  width: 50px;
  height: 50px;
`

const SvgIcon = styled.svg`
  width: ${(props) => props.size || '2.5rem'};
  height: ${(props) => props.size || '2.5rem'};
  margin-right: 1rem;
  fill: ${(props) => props.color || 'currentColor'};
`

const ListItemText = styled.p`
  font-size: 1.7rem;
  max-width: 20rem;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    max-width: 25rem;
  }
`

const CenterText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8.5rem;
  font-weight: 500;
  font-family: 'Marmelad', sans-serif;
`

const DescriptionCenter = styled.p`
  font-size: 1.8rem;
  text-align: left;
  margin-bottom: 2rem;
  margin-left: 5rem;
  max-width: 40rem;

  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
    margin-left: 0rem;
    margin: 2rem 5rem;
    text-align: center;
    max-width: 100%;
  }
`

export const GeneralDescription = () => {
  return (
    <Layout>
      <List>
        <ListItem>
          <SimplificationIcon as={SvgIcon} size='3rem' color='#ff0000' alt='risk description' />
          <ListItemText>Simplification de votre gestion</ListItemText>
        </ListItem>
        <ListItem>
          {/* <ListIcon src={riskIcon} alt='risk description' /> */}
          <RiskIcon as={ListIcon} alt='risk description' />
          <ListItemText>Atténuation de risques</ListItemText>
        </ListItem>
        <ListItem>
          {/* <ListIcon src={approcheHumaineIcon} alt='risk description' /> */}
          <ApprocheHumaineIcon as={ListIcon} alt='risk description' />
          <ListItemText>Approche humaine</ListItemText>
        </ListItem>
        <ListItem>
          {/* <ListIcon src={financialSecurityIcon} alt='risk description' /> */}
          <FinancialSecurityIcon as={ListIcon} alt='risk description' />
          <ListItemText>Sécurité financière</ListItemText>
        </ListItem>
      </List>
      <Row justify='center'>
        <CenterText>GIMQ</CenterText>
        <DescriptionCenter>
          Votre partenaire de confiance en gestion immobilière. Nous combinons expertise locale et approches personnalisées pour transformer la gestion de vos biens.
        </DescriptionCenter>
      </Row>
    </Layout>
  )
}
