import { create } from 'zustand'
import { authService } from '../authService'

export const useAuthStore = create((set, get) => ({
  isAuthenticated: false,
  userData: null, // firstName, lastName, email, etc.
  exp: null,
  accountType: null,
  error: null,
  loaded: false,

  checkAuthentication: async () => {
    const currentAuthState = get()
    if (currentAuthState.isAuthenticated) {
      return
    }

    try {
      const userInfo = await authService.checkAuthentication()
      console.log(userInfo, 'userInfo')
      set({
        isAuthenticated: true,
        userData: userInfo,
        accountType: userInfo.accountType,
        loaded: true,
      })
    } catch (error) {
      // Only set isAuthenticated to false if we haven't already authenticated the user.
      if (!currentAuthState.isAuthenticated) {
        set({
          isAuthenticated: false,
          error,
          loaded: true,
        })
      } else {
        // Handle the error without changing the authentication state.
        console.error('Error during re-check of authentication:', error)
      }
    }
  },

  login: async (credentials) => {
    try {
      const { exp, accountType, firstName, lastName, email } = await authService.login(credentials)
      set({
        isAuthenticated: true,
        userData: { firstName, lastName, email },
        loaded: true,
        exp,
        accountType,
      })
    } catch (error) {
      set({ error })
      throw error
    }
  },

  setAuthInfo: async (authInfo) => {
    set({
      isAuthenticated: true,
      userData: { firstName: authInfo.firstName, lastName: authInfo.lastName, email: authInfo.email },
      loaded: true,
      exp: authInfo.exp,
      accountType: authInfo.accountType,
    })
  },

  setNoAuth: async () => {
    set({
      isAuthenticated: false,
      userData: null,
      loaded: true,
      exp: null,
      accountType: null,
    })
  },

  logout: async () => {
    try {
      await authService.logout()
      set({ isAuthenticated: false, userData: null, exp: null, accountType: null })
    } catch (error) {
      set({ error })
    }
  },

  getUserData: async () => {
    try {
      const { _id, email, firstName, lastName, exp } = await authService.getUserData()
      set({
        userData: { _id, email, firstName, lastName },
        exp,
      })
    } catch (error) {
      set({ error })
    }
  },
}))

export default useAuthStore
export const authStore = useAuthStore
