import styled, { keyframes } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { animated } from 'react-spring'
import { motion } from 'framer-motion'

export const HeaderStyle = styled.header`
  position: fixed;
  top: 4rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75rem;
  height: 5.35rem;
  padding: 1.3rem 2rem;
  margin: auto;
  z-index: 5;
  border-radius: 1.25rem;

  opacity: 0.95;
  background-color: ${({ theme }) => theme.colors.body2};
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.45s ease-in-out;

  transform: translateX(${({ isOpen }) => (isOpen ? '150%' : '0')});
  transition-delay: ${({ isOpen }) => (isOpen ? '0s' : '0.8s')};
  /* 
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    bottom: 0;
    border: 2px solid ${({ theme }) => theme.colors.text}; 
    border: 2px solid pink;
  } */

  @media (max-width: 768px) {
    padding: 0.5rem 1.5rem;
    height: 5rem;
    width: 85%;
  }
`

export const MenuContent = styled(motion.div)`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 33%;
  height: 100vh;

  flex-direction: column;
  justify-content: center;
  /* background-color: rgba(255, 255, 255, 0.95); */
  background-image: linear-gradient(230deg, rgba(32, 34, 36, 1) 0%, #161616 100%);
  background-image: linear-gradient(230deg, ${({ theme }) => theme.colors.body} 0%, ${({ theme }) => theme.colors.body2} 100%);
  background-color: ${({ theme }) => theme.colors.body2};

  padding: 1rem;
  padding-left: 3rem;
  transition: transform 0.25s ease-in-out;
  transition-delay: ${({ isOpen }) => (isOpen ? '0.4s' : '0s')};
  transform: translateX(0);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 10;

  /* opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s, visibility 0.3s; */
  /* transform: translateX(${({ isOpen }) => (isOpen ? '0%' : '100%')}); */
  /* 
  & > a {
    margin: 0.5rem 0;
    font-size: 2.5rem; // Increased font size for better readability in a panel
  } */

  .fa-circle-half-stroke {
    color: #323232;
    margin-top: 0.5rem;
    font-size: 1.8rem;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.text};
      transition: all 0.25s ease-in-out;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const slideAnimation = keyframes`
 0% { opacity: 0; transform: translateX(-100%); transform: translate(-50%,-50%); } 
 40% { opacity: 1; transform: translateX(0%);
  transform: translate(0%,-50%); } 
  60% { opacity: 1; transform: translateX(0%); transform: translate(0%,-50%); } 
  100% { opacity: 0; transform: translateX(100%); transform: translate(-300%,-50%) }
`

export const slideAnimationMobile = keyframes`
 0% { opacity: 0; transform: translateX(-100%); transform: translate(-50%,-50%); } 
 40% { opacity: 1;
   /* transform: translateX(0%); */
  transform: translate(32%,-50%); } 
  60% { opacity: 1; 
    /* transform: translateX(32%); */
     transform: translate(32%,-50%); } 
  100% { opacity: 0; 
  /* transform: translateX(100%); */
   transform: translate(-300%,-50%) }
`

export const slideAnimation2 = keyframes`
  0% { opacity: 0; transform: translateX(-100%); transform: translate(-50%,-50%); } 
 40% { opacity: 1; transform: translateX(0%);
  transform: translate(0%,-50%); } 
  60% { opacity: 1; transform: translateX(0%); transform: translate(0%,-50%); } 
  100% { opacity: 0; transform: translateX(100%); transform: translate(-350%,-50%) }
`

export const slideAnimation2Mobile = keyframes`
  0% { opacity: 0;  transform: translate(-50%,-50%); } 
 40% { opacity: 1;
  transform: translate(16%,-50%); } 
  60% { opacity: 1; transform: translate(16%,-50%); } 
  100% { opacity: 0; transform: translateX(100%); transform: translate(-350%,-50%) }
`

export const AnimatedText = styled(animated.div)`
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(40%, -50%);
  font-size: 1.1rem;
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  font-weight: 500;
  white-space: nowrap;
  /* color: ${({ theme }) => theme.colors.primary}; */
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  color: ${({ theme }) => theme.colors.text};
`

export const Container = styled.div`
  /* position: fixed; */
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40rem;

  z-index: 999;
  overflow: hidden;
  transform: translateX(${({ open }) => (open ? '0' : '100%')});
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    transform: translateX(${({ open }) => (open ? '0' : '100%')});
  }
`

export const Logo = styled.img`
  height: 4.5rem;
  margin-right: 1rem;
  cursor: pointer;
`

export const CloseButton = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};

  position: absolute;
  top: 10px;
  right: 20px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;

  span {
    position: relative;
    width: 24px;
    height: 2px; /* We'll make the span the center bar of the X */
    background-color: ${({ theme }) => theme.colors.text};
    display: block;
    transform: translateY(-50%) rotate(45deg); /* Center the span vertically and rotate it */
    top: 50%;
    left: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%; /* Same width as the span */
      height: 100%; /* Same height as the span */
      background-color: ${({ theme }) => theme.colors.text};
      top: 0; /* Align with the span */
    }

    &::before {
      transform: rotate(-90deg); /* Perpendicular to the span */
    }

    &::after {
      transform: rotate(90deg); /* Perpendicular to the span */
    }
  }

  i {
    /* The styles for the <i> element can remain unchanged */
  }
`

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const NavLinkItem = styled(animated(Link))`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.body};
  animation: ${fadeIn} 0.5s ease-in-out;
  font-size: 2.15rem;
  font-size: 2.5rem;
  font-weight: 500;
  color: #323232;
  transition: all 0.25s ease-in-out;

  &.active {
    /* color: pink; */
    font-weight: 500;

    /* background: linear-gradient(
      135deg,
      ${({ theme }) => theme.colors.secondary} 0%,
      ${({ theme }) => theme.colors.tertiary} 45%,
      ${({ theme }) => theme.colors.body} 50%,
      ${({ theme }) => theme.colors.tertiary} 50%,
      pink 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone; */
    color: ${({ theme }) => theme.colors.text};
  }

  &:hover {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.colors.text} 0%,
      ${({ theme }) => theme.colors.text} 45%,
      ${({ theme }) => theme.colors.text} 75%,

      ${({ theme }) => theme.colors.body} 98%,
      ${({ theme }) => theme.colors.text} 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    transition: all 0.25s ease-in-out;
  }
`

export const HamburgerBar = styled.div`
  width: 3rem;
  height: 0.16rem;
  margin-right: 0.4rem;
  margin-left: auto;

  background-color: ${({ theme }) => theme.colors.text};
  transition: ${({ theme }) => theme.transitionSpeed} all ease-in-out;
  transition: 200ms all ease-in-out;

  &:first-of-type {
    // margin-top: 1rem;
    /* margin-top: 0.05rem; */
  }
  &:nth-of-type(2) {
    margin-top: 0.85rem;

    /* height: 0.14rem; */
  }
  &:nth-of-type(3) {
    margin-top: 0.85rem;
  }
`

export const Hamburger = styled.div`
  display: block;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    ${HamburgerBar} {
      &:first-of-type {
        width: 3.5rem;
      }
      &:nth-of-type(2) {
        width: 2.75rem;
      }
      &:nth-of-type(3) {
        width: 2rem;
      }
    }
  }
`

export const LogoShort = styled.img`
  position: relative;
  width: ${({ width }) => width || '3.5rem'};
  cursor: pointer;
  transform: translate(-11%, 2px);
  z-index: 3;
`

export const LogoShortBefore = styled.div`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.body};
  background-color: ${({ theme }) => theme.colors.body2};
  z-index: 2;
  width: 4.85rem;
  height: 100%;
  border-radius: 2.5rem;
  transform: scale(1.05);

  /* &:hover {
    transform: scale(1.05);
  } */
`
