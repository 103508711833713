import { Navigate } from 'react-router-dom'

import useAuthStore from '../../../../features/auth/authStore'
import { Page } from '../../../../components/base/layouts/Page'
import { Loader } from '../../../../components/base/layouts/Loader'

export const PrivateRoute = ({ children, requiredAccountType = null }) => {
  const { isAuthenticated, loaded, accountType } = useAuthStore()

  console.log(isAuthenticated, loaded, accountType, requiredAccountType, 'isAuthenticated, loaded, accountType, requiredAccountType')

  if (loaded) {
    if (isAuthenticated) {
      if (requiredAccountType === null || requiredAccountType === accountType || (Array.isArray(requiredAccountType) && requiredAccountType.includes(accountType))) {
        return <>{children}</>
      } else {
        return <Navigate to='/app/login' />
      }
    }

    return <Navigate to='/app/login' />
  }

  return (
    <Page>
      <Loader />
    </Page>
  )
}
