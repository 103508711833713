import React from 'react'
import styled from 'styled-components'
import { Col as ColRef } from '../../../components/base/layouts/Col'
import { Link } from 'react-router-dom'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('/bg-gimq.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 170vh;
`

const Col = styled(ColRef)`
  max-width: 80rem;
  margin-top: 30vh;
  margin-left: 10rem;
  padding: 0 2rem;
  text-align: left;
  > * {
    color: #1b75bc;
  }

  @media screen and (max-width: 768px) {
    margin-top: 20vh;
    margin-left: auto;
    max-width: 50rem;
  }
`

export const Title = styled.h1`
  font-size: 8rem;
  margin: 0;
  margin-top: 2rem;
  color: #1b75bc;

  @media screen and (max-width: 768px) {
    font-size: 5rem;
    text-align: left;
    margin-right: auto;
  }
`

export const Subheading = styled.h2`
  font-size: 4.5rem;
  margin: 0;
  margin-top: 2.5rem;

  @media screen and (max-width: 768px) {
    font-size: 3rem;
    text-align: left;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
`

export const Button = styled(Link)`
  max-width: 30rem;
  border-radius: 2.5rem;
  border: none;
  background-color: #fff;
  color: #1b75bc;
  margin-top: 4rem;
  padding: 1rem 2rem;
  font-size: 1.55rem;
  font-weight: 600;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  // animate-fill the button on hover (from left to right nice animation)
  background-image: linear-gradient(to right, #1b75bc 50%, transparent 50%);
  background-position: 100%;
  background-size: 200% 100%;
  transition: all 0.5s ease-out;

  &:hover {
    background-position: 0%;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.65rem;
  }
`

export const Hero = () => {
  return (
    <Layout>
      <Col>
        <Title>Là pour vous</Title>
        <Subheading>Derrière chaque porte : une gestion qui change la donne.</Subheading>
        <Button to='nos-services'>Découvrez nos services</Button>
      </Col>
    </Layout>
  )
}
