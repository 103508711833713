import React from 'react'
import styled from 'styled-components'
import { Hero } from './Hero'
import { GeneralDescription } from './GeneralDescription'
import { ServicesOverview } from './ServicesOverview'
import { ClientTestimonials } from './ClientTestimonials'
import { WhyGIMQ } from './WhyGIMQ'
import { CTASecondary } from './CTASecondary'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin: 0;
`

const HeroSubheading = styled.h2`
  font-size: 1.5rem;
  margin: 0;
`

const HeroImage = styled.img`
  width: 100%;
  height: auto;
`

export const Homepage = () => {
  return (
    <Layout>
      {/* <div
        className='fb-customerchat'
        attribution='setup_tool'
        page_id='100156852928498'
        theme_color='#0084ff'
        logged_in_greeting='Hi! How can we help you?'
        logged_out_greeting='Hi! How can we help you?'></div> */}
      <Hero />
      <GeneralDescription />
      <ServicesOverview />
      <ClientTestimonials />
      <WhyGIMQ />
      <CTASecondary />
    </Layout>
  )
}

export default Homepage
