import React, { createContext, useState, useContext } from 'react'

const UIContext = createContext()

export const useUI = () => useContext(UIContext)

const getInitialTheme = () => {
  const currentHour = new Date().getUTCHours() - 4 // Convert to Eastern Time
  return currentHour >= 6 && currentHour <= 20 ? 'dark' : 'dark'
}

export const UIProvider = ({ children }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [theme, setTheme] = useState(getInitialTheme())

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  const value = {
    menuIsOpen,
    setMenuIsOpen,
    toggleMenu,
    theme,
    toggleTheme,
  }

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}
