import api, { setAuthToken } from '../../../utils/api'

export const authService = {
  checkAuthentication: async () => {
    try {
      const response = await api.get('/auth/check')
      return response.data
    } catch (error) {
      console.error('Authentication check error:', error)
      throw error
    }
  },
  login: async (credentials) => {
    try {
      const response = await api.post('/auth/login', credentials)
      setAuthToken(response.data.token)
      localStorage.setItem('token', response.data.token)
      return response.data
    } catch (error) {
      console.error('Login error:', error)
      throw error
    }
  },
  logout: async () => {
    try {
      //await api.post('/auth/logout')
      setAuthToken(null)
      localStorage.removeItem('token')
    } catch (error) {
      console.error('Logout error:', error)
      throw error
    }
  },
  sendEmailValidation: async (email) => {
    await api.post('/auth/email-validation', { email })
  },
  getUserData: async () => {
    try {
      const response = await api.get('/user')
      return response.data
    } catch (error) {
      console.error('Get user data error:', error)
      throw error
    }
  },
  register: async (registrationData) => {
    console.log('registrationData', registrationData)
    let { data } = await api.post('/auth/register', registrationData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  },
}
