import styled from 'styled-components'
import { H2 } from '../ServicesOverview'

const Layout = styled.div`
  width: 100%;
  background: #2f3d4c;
  padding: 0 5rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const BoxPerson = styled.div`
  position: relative;
  background: url('/img/marco.png') no-repeat center center;
  position: relative;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 45rem;
  height: 65vh;
  margin: auto;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    height: 82vh;
    margin-bottom: 10rem;
    transform: translateY(-10%);
  }
`

const TextBox = styled.div`
  position: absolute;
  right: -100%;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: fit-content;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    position: relative;
    right: 0;
    top: 50%;
    width: 98%;
  }
`

const TextPerson = styled.p`
  position: relative;
  font-size: 1.7rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: white;
`

const HeadingPerson = styled.span`
  position: relative;
  font-size: 2rem;
  font-weight: bold;
  color: white;
`

const List = styled.ul`
  position: absolute;
  left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 80rem;
  margin: auto;
  padding: 0 2rem;
  margin-bottom: 5rem;

  @media screen and (max-width: 768px) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0;
    margin-bottom: 5rem;
    max-width: 100%;
    width: fit-content;
    margin: 0;
    left: 0;
  }
`

const ListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 47rem;
  height: 5.5rem;
  background: white;
  border-radius: 1rem;
  padding: 1rem 2rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  @media screen and (max-width: 1268px) {
    max-width: 100%;
    width: 90%;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
`

const ListIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 2.5rem;
  height: 2.5rem; */
  background: #1b75bc;
  border-radius: 50%;
  margin-right: 2rem;
  color: white;
  font-size: 2rem;
`

const ListItemText = styled.p`
  font-size: 1.7rem;
  font-weight: 500;
  color: #333;
`

export const WhyGIMQ = () => {
  return (
    <Layout>
      <H2>Pourquoi Choisir GIMQ ?</H2>
      <List>
        <ListItem>
          <ListIcon>
            <i className='fas fa-check'></i>
          </ListIcon>
          <ListItemText>Offre sur mesure, adaptée à vos besoins</ListItemText>
        </ListItem>
        <ListItem>
          <ListIcon>
            <i className='fas fa-check'></i>
          </ListIcon>
          <ListItemText>Assistance 24/7</ListItemText>
        </ListItem>
        <ListItem>
          <ListIcon>
            <i className='fas fa-check'></i>
          </ListIcon>
          <ListItemText>Service à la clientèle personnalisé</ListItemText>
        </ListItem>
      </List>
      <BoxPerson>
        <TextBox>
          <HeadingPerson>
            Marco Tremblay,
            <br />
            Expert en Immobilier et Propriétaire
          </HeadingPerson>

          <TextPerson>
            Marco Tremblay, influencé par l'entrepreneuriat familial, allie expertise en sciences sociales et passion pour l'immobilier. <br />
            <br />
            Sa carrière est marquée par une approche centrée sur le bien-être et la prospérité de ses clients, renforcée par une connaissance approfondie du secteur et une attitude positive et fiable.
            {/* Marco Tremblay a grandi entouré de travaux, de commerces et de projets immobiliers réalisés par ses parents, mère femme d'affaire et père homme à tout faire.
            <br />
            <br />
            Après avoir passé plusieurs années dans le domaine des sciences sociales, il s'est naturellement tourné vers l'immobilier. Ce domaine réuni autant l'attrait pour les affaires que les
            contacts humains.
            <br />
            <br />
            Il sait inspirer confiance par ses connaissances, et démontre une attitude positive, centrée sur le bien-être des personnes et leurs prospérités. */}
          </TextPerson>
        </TextBox>
      </BoxPerson>
    </Layout>
  )
}
