import React, { createContext, useEffect, useState } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { themeSettings } from '../theme-settings'

export const ThemeContext = createContext()

// Function to determine the initial theme based on the current hour
const getInitialTheme = () => {
  const currentHour = new Date().getUTCHours() - 4 // Convert to Eastern Time
  return currentHour >= 6 && currentHour <= 20 ? 'dark' : 'dark'
}

export const ThemeProvider = ({ children }) => {
  // Initialize theme state based on the current time
  // const initialTheme = getInitialTheme()
  const initialTheme = 'light'
  const [theme, setTheme] = useState(initialTheme)

  // Initialize themeMode based on the initial theme
  const themeMode = theme === 'light' ? themeSettings.light : themeSettings.dark

  // useEffect(() => {
  //   // Update theme state based on the current time
  //   // const currentTheme = getInitialTheme()
  //   // setTheme(currentTheme)
  // }, [])

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  useEffect(() => {
    console.log('theme changed to ' + theme)
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <StyledThemeProvider theme={themeMode}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  )
}
